<template>
  <div class="case">
    <div class="title">
      <h4>经典案例</h4>
      <div class="title__sub">
        <span>CASE</span>
        <span @click="goExample">MORE</span>
      </div>
    </div>

    <exam-imglist :caseInfo="caseInfo"></exam-imglist>
  </div>
</template>

<script>
import { examImglist } from '@/components/app/examImglist';
import axios from 'axios';
export default {
  data() {
    return {
      caseInfo: []
    };
  },
  components: { examImglist },
  mounted() {
    this.getSwiperData();
  },
  methods: {
    goExample() {
      this.$router.push('/Example');
    },
    getSwiperData() {
      const params = { PageIndex: 1, PageSize: 4 };
      axios.get('/api/Cases/Page', { params }).then(rs => {
        if (rs.data.Success) {
          let rows = rs.data.Data.Data;
          rows.forEach(ele => {
            const { Id: id, Title: text, Subtitle: subText, PhoneImgAddress: img } = ele;
            this.caseInfo.push({ ...ele, id, img, text, subText });
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.case {
  position: relative;
  padding: 40px 15px;

  .title {
    padding-bottom: 5px;
    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
    }

    &__sub {
      display: flex;
      padding-top: 3px;
      justify-content: space-between;
      font-size: 12px;

      & > span {
        &:first-child {
          color: #999;
        }

        &:last-child {
          padding-right: 17px;
          background: url(../../../assets/images_app/a_icon3.png) no-repeat right center;
          background-size: 12px auto;
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding: 80px 30px;

    .title {
      padding-bottom: 20px;
      h4 {
        font-size: 36px;
      }
      &__sub {
        padding-top: 6px;
        font-size: 24px;
        & > span {
          &:last-child {
            padding-right: 34px;
            background-size: 24px auto;
          }
        }
      }
    }
  }
}
</style>
