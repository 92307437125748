<template>
  <div class="case__cont">
    <div v-for="item in caseInfo" :key="item.text">
      <div class="case__cont__item" @click="goExampleDetail(item)">
        <div class="img">
          <img :src="item.img" alt />
        </div>
        <p class="txt">{{ item.text }}</p>
        <p>{{ item.subText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseInfo: {
      type: Array,
      default: []
    }
  },
  methods: {
    goExampleDetail({ Id, Type }) {
      this.$router.push({ path: '/ExampleDetail', query: { id: Id, type: Type } });
    }
  }
};
</script>

<style lang="scss" scoped>
.case__cont {
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;

  & > div {
    flex-basis: 50%;
    width: 50%;
    padding: 0 4px;
  }
  &__item {
    margin-top: 10px;
    padding: 2px;
    background: $red;

    .img {
      height: 200px;
      overflow: hidden;
      border-radius: 6px;
      img {
        width: 100%;
      }
    }
    & > p {
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.txt {
        padding-top: 15px;
        padding-bottom: 8px;
        font-size: 16px;
      }

      &:last-child {
        padding-bottom: 20px;
        font-size: 14px;
      }
    }
  }
  @media (min-width: 768px) {
    margin: 0 -8px;
    & > div {
      padding: 0 8px;
    }
    &__item {
      margin-top: 20px;
      padding: 4px;
      .img {
        height: 400px;
        border-radius: 12px;
      }

      & > p {
        padding-left: 20px;
        padding-right: 20px;

        &.txt {
          padding-top: 50px;
          padding-bottom: 16px;
          font-size: 36px;
        }

        &:last-child {
          padding-bottom: 70px;
          font-size: 28px;
        }
      }
    }
  }
}
</style>
