<template>
  <div class="swiper" id="page1" :style="{ height: fullScreenHeight }">
    <swiper ref="swiper1" :options="swiper1Options">
      <!-- swiper slide 1 -->
      <swiper-slide>
        <section class="banner">
          <img src="../../../assets/images_app/a_img1.png" alt />
          <div class="banner__cont">
            <h4>
              专注服务
              <br />世界500强、 <br />央企及金融业。
            </h4>
            <hr class="white-line" />
            <p class="serve-address">
              <span class="icon">&nbsp;</span>
              <span class="txt">北京</span>
              <span class="txt">深圳</span>
              <span class="txt">广州</span>
              <span class="txt">香港</span>
            </p>
          </div>
        </section>
      </swiper-slide>

      <!-- swiper slide 2 -->
      <swiper-slide>
        <section class="banner">
          <img src="../../../assets/images_app/a_img6.png" alt />
          <div class="banner__cont">
            <div class="cont__block">
              <div class="cont__block__item">
                <span class="font-large">10</span>
                年的专注
              </div>
              <div class="cont__block__item">
                <span class="font-large">30 +</span>
                品牌服务经验
              </div>
              <div class="cont__block__item">
                <span class="font-large">500 +</span>
                个金融服务案例
              </div>
            </div>
            <hr class="white-line" />
            <p class="serve-address">
              <span class="icon">&nbsp;</span>
              <span class="txt">北京</span>
              <span class="txt">深圳</span>
              <span class="txt">广州</span>
              <span class="txt">香港</span>
            </p>
          </div>
        </section>
      </swiper-slide>

      <!-- swiper slide 3 -->
      <swiper-slide>
        <section class="banner">
          <img src="../../../assets/images_app/a_img7.png" alt />
          <div class="banner__cont">
            <h4>持续 ；专注</h4>
            <ul class="cont__list">
              <li v-for="(item, index) in listItems" :key="index">
                <span class="time">{{ item.time }}</span>
                {{ item.txt }}
              </li>
            </ul>
            <hr class="white-line w170" />
          </div>
        </section>
      </swiper-slide>

      <!-- pagination  -->
      <div class="swiper-pagination text-right app__page--1" slot="pagination"></div>
    </swiper>

    <span class="next-page" @click="nextPage"></span>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    const vueSelf = this;
    return {
      fullScreenHeight: 0,
      swiper1Options: {
        direction: 'horizontal',
        autoplay: {
          disableOnInteraction: false,
          delay: 4000
        },
        keyboard: true,
        loop: true,
        pagination: {
          el: '.app__page--1',
          type: 'custom',
          renderCustom: function(swiper, current, total) {
            return `<div> ${current < 10 ? '0' + current : current} <div class='time-pro'><div></div></div> ${current + 1 > total ? '01' : current + 1 < 10 ? '0' + (current + 1) : current + 1}</div>`;
          }
        },
        on: {
          async init() {
            const result = await vueSelf.getBannerList();
            const bannerList = result.data.Data.Data;
            for (let i = 0; i < this.slides.length; i++) {
              let swiperSlideIndex = this.slides[i].dataset.swiperSlideIndex;
              this.slides[i].firstChild.children[0].src = bannerList[swiperSlideIndex].PhoneURL;
            }
          }
        }
      },
      listItems: [
        { time: '2023', txt: '第9届TMA案例类-内容营销类奖' },
        { time: '2023', txt: '第9届TMA案例类-娱乐营销类品牌代言奖' },
        { time: '2023', txt: '第9届TMA行业类-品牌代言奖' },
        { time: '2023', txt: '第六届DMAA金融类奖' },
        { time: '2023', txt: '第23届IAI传鉴国际广告奖' },
        { time: '2022', txt: '第13届金鼠标数字营销大赛跨媒体整合类奖' },
        { time: '2022', txt: '第13届金鼠标数字营销大赛话题营销类奖' },
        { time: '2022', txt: '第13届金鼠标数字营销大赛评选委员会 终审评委' },
        { time: '2021', txt: ' ECI AWARDS 国际艾奇奖-营销创新类奖项' },
        { time: '2021', txt: '中国数字化转型与创新评选-年度网络创新产品奖' },
        { time: '2020', txt: '第十一届虎啸奖内容营销类优秀奖' },
        { time: '2020', txt: '第十一届虎啸奖金融/保险之产品与服务类等级奖' },
        { time: '2020', txt: '第20届IAI国际广告奖金融营销/银行金奖' },
        { time: '2020', txt: '第11届金鼠标数字营销大赛年度数字营销创新力代理公司' },
        { time: '2019', txt: '数字化转型与创新评选“年度数字金融解决方案”奖' },
        { time: '2019', txt: '中国国际旅游交流会最佳广告奖' },
        { time: '2019', txt: '中国国际旅游交流会最佳组织奖' },
        { time: '2019', txt: '中国国际旅游交流会最佳展台奖' },
        { time: '2019', txt: '深圳国际文化产业博览会优秀展示奖' },
        { time: '2019', txt: '金远奖全场大奖' },
        { time: '2016', txt: '中国金融营销金栗子奖最佳传播奖' },
        { time: '2012', txt: '广州日报华文报纸广告奖' },
        { time: '2011', txt: '中国金融品牌十大年度广告' }
      ]
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper1.swiper;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fullScreenHeight = window.innerHeight - document.getElementById('page1').parentElement.previousElementSibling.clientHeight + 'px';
    });
  },
  methods: {
    nextPage() {
      this.aniScroll(0, window.innerHeight);
    },
    aniScroll(nowScroll, maxScroll) {
      if (nowScroll < maxScroll) {
        setTimeout(() => {
          nowScroll += 10;
          document.getElementById('app').children[0].scrollTop = nowScroll;
          this.aniScroll(nowScroll, maxScroll);
        }, 2);
      }
    },
    async getBannerList() {
      const params = { Disable: false, PageIndex: 1, PageSize: 999 };
      return await axios.get('/api/Banner/Page', { params: params });
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper {
  position: relative;
  & > div,
  /deep/ .swiper-wrapper {
    height: 100%;
  }
}

.banner {
  position: relative;
  height: 100%;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  &__cont {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    padding: 1px 0 1px 25px;
    flex-direction: column;
    justify-content: center;

    h4 {
      margin: 0;
      font-size: 32px;
      line-height: 50px;
    }
    hr {
      width: 60px;
      height: 3px;
    }
    .serve-address {
      display: flex;
      margin: 0;
      padding-top: 15px;
      font-size: 14px;
      align-items: center;

      span {
        padding: 0 2%;
      }
      .icon {
        padding: 3% 4%;
        background: url(../../../assets/images_app/a_icon1.png) no-repeat left center;
        background-size: auto 100%;
      }
    }

    @media (min-width: 768px) {
      padding-left: 50px;
      h4 {
        font-size: 64px;
        line-height: 100px;
      }
      hr {
        width: 120px;
        height: 6px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .serve-address {
        padding-top: 30px;
        font-size: 26px;
      }
    }
  }
}

.cont {
  &__block {
    &__item {
      display: inline-block;
      width: 110px;
      height: 60px;
      margin-right: 3%;
      margin-bottom: 15px;
      border: 1px solid #fff;
      text-align: center;
      font-size: 14px;
    }
    .font-large {
      display: block;
      padding: 3px 0 2px;
      font-size: 20px;
    }
  }

  &__list {
    padding: 15px 0 0;
    li {
      line-height: 24px;
      font-size: 14px;
      .time {
        display: inline-block;
        padding-right: 5px;
      }
    }
  }

  @media (min-width: 768px) {
    &__block {
      &__item {
        width: 210px;
        height: 103px;
        margin-bottom: 40px;
        font-size: 26px;
      }
      .font-large {
        font-size: 38px;
      }
    }
    &__list {
      li {
        line-height: 40px;
        font-size: 22px;
      }
    }
  }
}

// next page icon
.next-page {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translateX(-50%);
  cursor: pointer;
  background: url(../../../assets/images_app/a_icon2.png) no-repeat center;
  background-size: 100% auto;
  z-index: 3;

  @media (min-width: 768px) {
    bottom: 70px;
    width: 50px;
    height: 50px;
  }
}

// 分页
/deep/ .app__page--1 > div {
  padding-right: 15px;
  // 时间条
  /deep/ .time-pro {
    position: relative;
    display: inline-block;
    top: -3px;
    width: 100px;
    height: 6px;
    margin: 0 5px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;

    & > div {
      display: block;
      width: 0%;
      height: inherit;
      background: $red;
      opacity: 0.8;
      -moz-animation: mozWidthPer 4.2s linear forwards;
      -webkit-animation: webkitWidthPer 4.2s linear forwards;
      animation: widthPer 4.2s linear forwards;
    }
  }
}
</style>
