<template>
  <footer class="footer">
    <img src="../../../assets/images_app/logo1.png" alt />
    <div class="footer__info">
      <p>电话：020-88526087</p>
      <p>邮箱：hr@mixdo.net</p>
      
        <p class="split">北京市丰台区丰台科技园汽车博物馆东路8号院金茂广场3号楼906</p>
        <p>深圳市福田区喜年中心A座1809</p>
        <p>广州市天河区华强路3-2号富力盈力大厦-北塔1303</p>
        <p>香港中环德辅道中130-132号大生银行大厦12楼1201</p>
      <div class="footer__info__img">
        <img src="../../../assets/images_app/icon3.png" alt />
        <img src="../../../assets/images_app/icon4.png" alt />
      </div>
    </div>
    <div class="footer__copyright">© 2020 MIXDO. All rights reserved <a href="https://beian.miit.gov.cn">粤ICP备18032146号-1</a></div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding: 40px 15px 0;
  & > img {
    height: 29px;
  }

  &__info {
    padding: 22px 15px 25px;
    p {
      margin: 0;
      line-height: 1.4;
      font-size: 12px;
      &.split {
        margin-top: 15px;
      }
      & + p {
        margin-top: 8px;
      }
    }

    &__img {
      padding-top: 10px;
      img {
        width: 24px;
        & + img {
          margin-left: 5px;
        }
      }
    }
  }

  &__copyright {
    margin: 0 -15px;
    padding: 5px 15px;
    line-height: 20px;
    font-size: 12px;
    background: $red;
    color: #ead4d4;
    text-align: center;
  }

  @media (min-width: 768px) {
    padding: 80px 30px 0;
    & > img {
      height: 58px;
    }

    &__info {
      padding: 44px 30px 50px;
      p {
        line-height: 38px;
        font-size: 20px;
        &.split {
          margin-top: 16px;
        }
      }

      &__img {
        padding-top: 20px;
        img {
          width: 44px;
          & + img {
            margin-left: 10px;
          }
        }
      }
    }

    &__copyright {
      margin: 0 -30px;
      padding: 10px 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }
}
</style>
