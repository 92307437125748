<template>
  <div class="custom">
    <div class="title">
      <h4>我们的客户</h4>
      <small>OUR CLIENT</small>
    </div>
    <div class="logos">
      <span v-for="item in logos" :key="item.key">
        <img :src="item.PhoneImgUrl" alt />
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      logos: []
    };
  },
  mounted() {
    this.getCustoms();
  },
  methods: {
    getCustoms() {
      const params = { Name: '', PageIndex: 1, PageSize: 999 };
      axios.get('/api/Customer/Page', { params }).then(rs => {
        if (rs.data.Success) {
          let datas = rs.data.Data.Data || [];
          datas.forEach((ele, index) => {
            this.logos.push({ key: 'logos_' + index, ...ele });
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom {
  position: relative;
  margin-top: 5px;
  padding: 35px 15px 40px;
  background: $red;
  color: #fff;
  .title {
    padding-bottom: 10px;
    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
    }
    small {
      font-size: 12px;
      color: #e09690;
    }
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    & > span {
      display: flex;
      flex-basis: 33.33%;
      width: 33.33%;
      height: 25px;
      margin-top: 20px;
      padding: 0 10px;
      align-items: center;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    margin-top: 10px;
    padding: 70px 30px 80px;

    .title {
      padding-bottom: 20px;
      h4 {
        font-size: 36px;
      }
      small {
        font-size: 28px;
      }
    }

    .logos {
      margin: 0 -20px;

      & > span {
        height: 50px;
        margin-top: 30px;
        padding: 0 20px;
      }
    }
  }
}
</style>
