<template>
  <div class="home">
    <swiper-h></swiper-h>
    <example></example>
    <custom></custom>
    <team></team>
    <hfooter></hfooter>
  </div>
</template>

<script>
import swiperH from "./swiperH.vue";
import example from "./example.vue";
import custom from "./custom.vue";
import team from "./team.vue";
import { hfooter } from "@/components/app/footer";

export default {
  components: { swiperH, example, custom, team, hfooter },
};
</script>

<style lang="scss" scoped>
</style>