<template>
  <div class="team">
    <div class="team__bg">
      <img src="../../../assets/images_app/img6.png" alt />
    </div>
    <div class="team__cont">
      <div class="title">
        <h4>我们的团队</h4>
        <small>OUR TEAM</small>
      </div>
    </div>
    <dl class="team__contact">
      <dd v-for="item in contactList" :key="item.id">{{ item.text }}</dd>
      <dd class="last" @click="goContact">CONTACT US</dd>
    </dl>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactList: [
        { id: 0, text: '招商银行总行十年品牌合作伙伴；' },
        { id: 1, text: '招银理财成立至今年度品牌合作伙伴；' },
        { id: 2, text: '工商银行广州分行五年品牌合作伙伴；' },
        { id: 3, text: '青岛银行十年品牌合作伙伴；' },
        { id: 4, text: '华侨城集团五年年度品牌合作伙伴；' },
        { id: 5, text: '广州南粤银行品牌合作伙伴；' },
        { id: 6, text: '广州农商银行品牌合作伙伴。' }
      ]
    };
  },
  methods: {
    goContact() {
      this.$router.push('/Contact');
    }
  }
};
</script>

<style lang="scss" scoped>
.team {
  position: relative;
  color: #fff;

  &__bg {
    img {
      display: block;
      width: 100%;
    }
  }

  &__cont {
    position: absolute;
    left: 0;
    top: 0;
    padding: 40px 15px;
  }

  .title {
    padding-bottom: 10px;
    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
    }
    small {
      font-size: 12px;
      color: #bdb9b3;
    }
  }

  &__contact {
    position: relative;
    margin-top: -80px;
    margin-left: 15px;
    padding: 35px 15px 40px;
    background: rgba(192, 45, 32, 0.8);
    z-index: 3;
    dd {
      margin: 0;
      line-height: 24px;
      font-size: 14px;
      &.last {
        position: absolute;
        right: 15px;
        bottom: 15px;
        padding-right: 15px;
        background: url(../../../assets/images_app/a_icon3.png) no-repeat right center;
        background-size: auto 10px;
        cursor: pointer;
      }
    }
  }

  @media (min-width: 768px) {
    &__cont {
      padding: 80px 30px;
    }

    .title {
      padding-bottom: 20px;
      h4 {
        font-size: 36px;
      }
      small {
        font-size: 28px;
      }
    }

    &__contact {
      margin-top: -160px;
      margin-left: 30px;
      padding: 70px 30px 80px;
      dd {
        line-height: 54px;
        font-size: 28px;
        &.last {
          right: 30px;
          bottom: 70px;
          padding-right: 30px;
          background-size: auto 19px;
        }
      }
    }
  }
}
</style>
